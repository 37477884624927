import React from "react";
import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import { GetErrorHandler } from "../../components/GetErrorHandlerHelper";
import { MaterialReactTable } from "material-react-table";
import { request } from "../../api/request";

const columns = [
  { accessorKey: "id", header: "ID" },
  { accessorKey: "discount_code", header: "Discount Code" },
  { accessorKey: "payment_method", header: "Payment Method" },
  { accessorKey: "total", header: "Total" },
  {
    accessorKey: "total_order_after_discount",
    header: "Final Total",
    //     Cell: ({ row }) => {
    //       // Check if payment_method is 'code', then calculate final total accordingly
    //       const finalTotal = row.original.payment_method === 'code'
    //       ? row.original.total + (row.original.total * 0.15) - row.original.total_order_after_discount
    //       :  row.original.total + (row.original.total * 0.15) ;
    // return <span>{finalTotal.toFixed(2)}</span>; // toFixed(2) for rounding to two decimal places
    //     },
  },
  {
    accessorKey: "order_state",
    header: "State",
    Cell: ({ cell }) => (
      <Box
        sx={{
          p: 1,
          borderRadius: "12px",
          backgroundColor:
            cell.getValue() === 4
              ? "green"
              : cell.getValue() === 5
              ? "red"
              : "yellow",
          color: "white",
          textAlign: "center",
          textTransform: "capitalize",
        }}
      >
        {cell.getValue() === 4
          ? "Paid"
          : cell.getValue() === 5
          ? "Deleted"
          : ""}
      </Box>
    ),
  },
  // ... other column definitions
];

const orderItemsColumns = [
 // { accessorKey: "id", header: "Item ID" },
  { accessorKey: "meal.name", header: "Name" },
  { accessorKey: "quantity", header: "Quantity" },
  { accessorKey: "meal.price", header: "Price" },
];

const getOrdersFromServer = () => {
  return request({
    url: "/orders",
  });
};

const Orders = () => {
  const ordersQuery = useQuery({
    queryKey: ["get-orders-from-server"],
    queryFn: getOrdersFromServer,
  });

  if (ordersQuery.isLoading) {
    return <Loader />;
  }

  if (ordersQuery.isError) {
    return (
      <GetErrorHandler
        error={ordersQuery.error}
        refetch={ordersQuery.refetch}
      />
    );
  }
console.log(ordersQuery.data.data)
  return (
    <Box
      sx={{
        height: 300,
        maxWidth: "100%",
      }}
    >
      <MaterialReactTable
        columns={columns}
        data={ordersQuery.data.data.data}
        renderDetailPanel={({ row }) => {
          return (
            <MaterialReactTable
              columns={orderItemsColumns}
              data={row.original.order_items}
            />
          );
        }}
      />
    </Box>
  );
};

export default Orders;
