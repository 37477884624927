import { ProductionQuantityLimitsOutlined } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'

const StatisticsCard = ({title , value}) => {
    const randomDegree = Math.floor(Math.random() * 360)
  return (
    <Box
        sx={{
            display : 'flex',
            alignItems : 'center',
            justifyContent : 'center',
            width : '100%',
            height : '100%',
            padding  : '30px',
            gap : '15px',
            boxShadow : '1px 1px 10px -5px #b91c1c',
            borderRadius : '15px',
            transition : '0.3s',
            "&:hover" : {
                transform : 'scale(0.98)'
            }
        }}
    >
        <Box
            sx={{
                // boxShadow : '1px 1px 10px -5px #BD764F',
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                padding  :'10px',
                borderRadius : '50%'
            }}
        >
            <IconButton
                sx={{
                    bgcolor : '#b91c1c',
                    "&:hover" : {
                        bgcolor : '#b85726',
                    }
                }}
            >
                <ProductionQuantityLimitsOutlined 
                    sx={{
                        color : '#fff',
                    }}
                />
            </IconButton>
        </Box>
        <Box>
            <Typography
                sx={{
                    fontSize : '20px',
                    color : '#b91c1c'
                }}
            >
                {value}
            </Typography>
            <Typography
                sx={{
                    color : '#b91c1c',
                    fontSize : '14px',
                    fontWeight : 'bold'
                }}
            >
                {title}
            </Typography>
        </Box>
    </Box>
  )
}

export default StatisticsCard