import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useQuery } from "@tanstack/react-query";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { request } from "../../api/request";

const filterOptions = ["day", "month", "year"];
const OrderStatistics = () => {
  const [filterState, setFilterState] = useState("day");
  const [from, setFrom] = React.useState(dayjs());
  const [to, setTo] = React.useState(dayjs());

  const getOrderStatistics = useCallback(() => {
    const formattedFrom = from.format("YYYY-MM-DD");
    const formattedTo = to.format("YYYY-MM-DD");
    return request({
      url: `/orderStatistics`,
      method: "POST",
      data: {
        state: filterState,
        date1: formattedFrom,
        date2: formattedTo,
      },
    });
  }, [filterState, from, to]);

  const getOrderStatisticsQuery = useQuery({
    queryKey: ["get-order-statistics"],
    queryFn: getOrderStatistics,
  });

  useEffect(() => {
    getOrderStatisticsQuery.refetch();
  }, [filterState, from, to]);

  return (
    <Grid container spacing={3}>
      <Grid item>
        <Box
          sx={{
            my: 2,
            backgroundColor: "#fff",
            padding: "10px",
            borderRadius: "2px",
            display: "flex",
            alignItems: "flex-end",
            gap: "20px",
          }}
        >
          <Select
            value={filterState}
            onChange={(e) => {
              setFilterState(e.target.value);
            }}
          >
            {filterOptions.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={filterState === "between" ? "From" : filterState}
              value={from}
              onChange={(newValue) => setFrom(newValue)}
            />

            {filterState === "between" ? (
              <DatePicker
                label="To"
                value={to}
                onChange={(newValue) => setTo(newValue)}
              />
            ) : undefined}
          </LocalizationProvider>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            width: { xs: "100%", sm: "80%", md: "60%" },
            margin: "30px auto",
            borderRadius: "8px",
            backgroundImage: "linear-gradient(90deg , red , black)",
            padding: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "40px",
                  color: "white",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {getOrderStatisticsQuery.isLoading ||
                getOrderStatisticsQuery.isRefetching
                  ? "loading ..."
                  : getOrderStatisticsQuery?.data?.data?.order_count}
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Order Count
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "40px",
                  color: "white",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
              >
                {getOrderStatisticsQuery.isLoading ||
                getOrderStatisticsQuery.isRefetching
                  ? "loading ..."
                  : 
                  parseFloat(getOrderStatisticsQuery?.data?.data?.total_order).toFixed(2) + " SAR"
                }
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                Total sales
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderStatistics;
